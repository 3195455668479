.list::-webkit-scrollbar {
  display: none !important;
} /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid rgb(43, 89, 240);
  border-color: rgb(43, 89, 240) transparent rgb(43, 89, 240) transparent;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}
#scroll::-webkit-scrollbar {
  width: 4.2px;
  background-color: #fafafa;
}
#scroll::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 12px;
}
#scroll::-webkit-scrollbar-track {
  border: 2px solid white;
}
#acscroll::-webkit-scrollbar {
  width: 3.2px;
  background-color: #fafafa;
}
#acscroll::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 12px;
}
#acscroll::-webkit-scrollbar-track {
  border: 2px solid white;
  margin-right: 2px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  /* unicode-bidi: bidi-override;
  direction: rtl; */
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
.noTrack::-webkit-scrollbar-track {
  box-shadow: none !important;
  border-radius: 10px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(241 154 48);
  border-radius: 10px;
}
.scrollBluePrimary::-webkit-scrollbar {
  height: 5px;
  border: 5px solid rgb(232, 227, 227);

  background-color: rgb(232, 227, 227);
}

.scrollBluePrimary::-webkit-scrollbar-thumb {
  background-color: rgb(241 154 48);
  background-clip: padding-box;
  border-radius: 15px;
  height: 3px;
}
.scrollBluePrimary::-webkit-scrollbar-thumb:hover {
  background: rgb(241 154 48);
}

/* thin */

.scrollBluePrimarythin::-webkit-scrollbar {
  height: 3px;
  border: 5px solid rgb(232, 227, 227);

  background-color: rgb(232, 227, 227);
}

.scrollBluePrimarythin::-webkit-scrollbar-thumb {
  background-color: rgb(241 154 48);
  background-clip: padding-box;
  border-radius: 15px;
  height: 1px;
}
.scrollBluePrimarythin::-webkit-scrollbar-thumb:hover {
  background: rgb(241 154 48);
}

.scrollBlue::-webkit-scrollbar {
  height: 7px;
  border: 5px solid rgb(59, 105, 176);

  background-color: rgb(59, 105, 176);
}

.scrollBlue::-webkit-scrollbar-thumb {
  background-color: white;
  background-clip: padding-box;
  border: 0.05px solid rgb(59, 105, 176);
  border-radius: 15px;
  height: 5px;
}
.scrollBlue::-webkit-scrollbar-thumb:hover {
  background: white;
}
.scrolllightblue::-webkit-scrollbar {
  height: 7px;
  border: 5px solid #03a9f4;
  border-radius: 0 0 7px 7px;
  background-color: #03a9f4;
}

.scrolllightblue::-webkit-scrollbar-thumb {
  background-color: white;
  background-clip: padding-box;
  border: 0.05px solid #03a9f4;
  border-radius: 15px;
  height: 5px;
}
.scrolllightblue::-webkit-scrollbar-thumb:hover {
  background: white;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(241 154 48);
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(241 154 48);
  border-radius: 10px;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.mainDivForLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

/* Width  */

.width-100 {
  width: 100% !important;
}
.widthFC {
  width: fit-content !important;
}
.width-50 {
  width: 50% !important;
}
.widthAuto {
  width: auto !important;
}
.minWidthFC {
  min-width: fit-content !important;
}
/* Height */

.height-100 {
  height: 100% !important;
}

.height-100vh {
  height: 100vh !important;
}

.heightAuto {
  height: auto !important;
}

.heightFC {
  height: fit-content !important;
}
.minHeightFC {
  min-height: fit-content !important;
}

.height-100-49 {
  height: calc(100% - 49px);
}
/* Fonts */
.fontWeightBold {
  font-weight: bold !important;
}
.fontSize1 {
  font-size: 1rem !important;
}
.fontSize09 {
  font-size: 0.9rem !important;
}
.textC {
  text-transform: capitalize !important;
}
.textTU {
  text-transform: uppercase !important;
}

/* Flex */

.d-flex {
  display: flex !important;
}
.d-flex-column {
  flex-direction: column !important;
}
.d-flex-row {
  flex-direction: row !important;
}
.justifyC {
  justify-content: center !important;
}
.justifySB {
  justify-content: space-between !important;
}
.justifySA {
  justify-content: space-around !important;
}
.justifySE {
  justify-content: space-evenly !important;
}
.justifyFE {
  justify-content: flex-end !important;
}
.alignC {
  align-items: center !important;
}
.alignFE {
  align-items: flex-end !important;
}

/* Positions */

.postionR {
  position: relative !important;
}
.positionAB {
  position: absolute !important;
}
.positionFX {
  position: fixed !important;
}
.right0 {
  right: 0 !important;
}
.left0 {
  left: 0 !important;
}
.bottom0 {
  bottom: 0 !important;
}
.top0 {
  top: 0 !important;
}
/* Texts */

.flexWrap {
  flex-wrap: wrap !important;
}

.textDecorU {
  text-decoration: underline !important;
}
.textOEllipse {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}
.textColorprimary {
  color: rgb(241 154 48) !important;
}

.wordBreak {
  word-break: break-word;
}
/*  */
.backgroundColorGrey {
  background-color: #9e9e9e0d;
}
.backgroundColorPrimary {
  background-color: rgb(241 154 48);
}
.cursorP {
  cursor: pointer !important;
}
.backgroundWhite {
  background-color: white !important;
}
.dashedDividerRight {
  background: linear-gradient(rgba(0, 0, 0, 0.12) 54%, #fff 0%) right/1px 31px
    repeat-y !important;
  border: none !important;
}
.dashedDividerBottom {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.12) 54%, #fff 0%)
    bottom/31px 1px repeat-x !important;
  border: none !important;
}

.borderRadius13 {
  border-radius: 13px !important;
}
.boxShadow {
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgba(251, 224, 191, 1);
}
.borderRadius7 {
  border-radius: 7px !important;
}
.fontWeight500 {
  font-weight: 500 !important;
}
.fontWeight400 {
  font-weight: 400 !important;
}
.fontWeight600 {
  font-weight: 600 !important;
}

.paddingTB20 {
  padding: 20px 0 !important;
}
.paddingT15 { padding: 15px 0 0 0 !important;
}
.paddingTB10 {
  padding: 10px 0 !important;
}
.padding5 {
  padding: 5px !important;
}
.padding10 {
  padding: 10px !important;
}
.padding15 {
  padding: 15px !important;
}
.paddingRLB5 {
  padding: 0 5px 5px 5px;
}
.paddingRL5 {
  padding: 0 5px 0 5px;
}
.paddingRL10 {
  padding: 0 10px 0 10px;
}

.displayNone {
  display: none;
}

.padding20 {
  padding: 20px !important;
}
.textCW {
  color: white !important;
}
.marginTB20 {
  margin: 20px 0px !important;
}
.marginB10 {
  margin-bottom: 10px !important;
}
.marginR10 {
  margin: 0 10px 0 0px !important;
}
.margin10 {
  margin: 10px !important;
}
.marginTB10 {
  margin: 10px 0px !important;
}
.marginB20 {
  margin: 0 0 20px 0px !important;
}
.overflowYA {
  overflow-y: auto !important;
}

.overflowXA {
  overflow-x: auto !important;
}
.overflowH {
  overflow: hidden !important;
}

.padding3 {
  padding: 2px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.height-6799 {
  height: calc(100% - 67.99px) !important;
}
.height-60 {
  height: 60px !important;
}
.fontSize08 {
  font-size: 0.8rem !important;
}
.fontSize07 {
  font-size: 0.7rem !important;
}
.pNone {
  pointer-events: none !important;
}
.marginT10B20 {
  margin: 10px 0 20px 0 !important;
}
.zIndex1 {
  z-index: 1 !important;
}
.textColorGrey {
  color: rgb(128, 128, 128);
}
.marginT10 {
  margin: 10px 0 0 0;
}
.margin0 {
  margin: 0;
}
.viewer-icon {
  fill: none;
  stroke: white !important;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2 !important;
  text-align: center;
}
.viewer-zoom-popover-target-arrow {
  border-color: white transparent transparent !important;
  border-style: solid;
  border-width: 8px 4px 0px;
  height: 0px;
  /* width: 0px; */
}
.example::-webkit-scrollbar {
  display: none;
  width: 0px;
  height: 0px;
}
iframe {
  overflow: hidden !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.disabled {
  pointer-events: none !important;
}
.textColorBlack {
  color: rgba(0, 0, 0, 0.87) !important;
}
.backgroundTransparent {
  background: transparent !important;
}
.marginR25 {
  margin: 0 25px 0 0;
}
.hoverTwo {
  background-color: white;
  cursor: pointer;
}
.hoverTwo:hover {
  background-color: rgb(0 0 0 / 30%) !important;
  color: black;
}
.BreadcrumbTitle {
  font-weight: 400;
  line-height: 1.5;
  font-size: 1.25rem;
}
.BreadcrumbTitleSelected {
  color: #4b87c5;
  cursor: pointer;
}
.dividerBorder {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.progressbar {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: green;
  z-index: -1;
  border-radius: 7px 0 0 7px;
  color: white !important;
}
.upload-a-file-container-assignment-error {
  width: 100%;
  height: auto;
  min-height: 70px;
  background-color: white;
  border: 2px dashed #db0011;
  border-radius: 3%;
  display: flex;
  color: #757575;
  word-break: break-word;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}
.upload-a-file-container-assignment {
  width: 100%;
  height: auto;
  min-height: 70px;
  cursor: pointer;
  background-color: white;
  border: 2px dashed rgb(241 154 48);
  border-radius: 3%;
  display: flex;
  color: rgb(241 154 48);
  word-break: break-word;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.upload-a-file-content-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
  color: rgb(241 154 48);
}
.upload-a-file-icon-container {
  margin: 0 3% 0;
  width: auto;
  height: auto;
  display: flex;
}
.objFC {
  object-fit: contain;
}
.leftText {
  color: rgb(112, 112, 112);
  font-size: 0.9rem;
  font-weight: 300;
  margin: 0px;
  text-align: left;
  max-width: calc(100% - 21px);
  width: calc(100% - 21px);
}
.leftText1 {
  color: rgb(112, 112, 112);
  font-size: 0.9rem;
  font-weight: 300;
  margin: 0px;
  text-align: left;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield !important;
}
.textAC {
  text-align: center;
}
.footer {
  page-break-after: always !important;
}
@keyframes glowing {
  0% {
    background-color: #ff3d00;
    box-shadow: 0 0 5px #ff3d00;
  }
  50% {
    background-color: #ff3d00;
    box-shadow: 0 0 20px #ff3d00;
  }
  100% {
    background-color: #ff3d00;
    box-shadow: 0 0 5px #ff3d00;
  }
}
.buttonAnimation {
  animation: glowing 1300ms infinite;
}
.height100vh {
  height: 100vh;
}
.linkStyle {
  /* text-decoration: underline; */
  /* text-underline-offset: 3px !important; */
  color: rgb(241 154 48) !important;
  cursor: pointer; 
  font-weight: 550;
}
.linkStyle:hover {
  color: rgb(241 154 48) !important;
 
  text-decoration-color: rgb(241 154 48) !important;
  text-underline-offset: 3px !important;
}
.column-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  color:grey;/* Adjust alignment as needed */
}