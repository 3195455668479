.chatActionWrapper {
  width: 100% !important;
}
.chatAction {
  background: #f7f7f7 !important;
  /* backdrop-filter: blur(10px) !important; */
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 15px 15px 0 0 !important;
  /* max-width: 380px !important; */
  width: 100% !important;
  height: 60px !important;
  /* padding: 10px; */
  min-height: 60px;
  margin-left: 20px;
}
.BuyButton {
  width: 62px !important;
  height: 40px !important;
  background: #005079 !important;
  border-radius: 7px !important;
  margin-right: 7px !important;
  font-size: 14px !important;
  border: 1px solid #005079 !important;
}
.sellButton {
  width: 62px !important;
  height: 40px !important;
  background: #0092dd !important;
  border-radius: 7px !important;
  margin-right: 8px !important;
  border: 1px solid #0092dd !important;
}
.infinite-scroll-component__outerdiv {
  width: 100% !important;
  height: 100% !important;
  min-height: 100% !important;
  margin: 0 !important;
}

.chatsListWrapper {
  /* margin: 0 -17px; */
  /* padding: 0 17px; */
  width: 100%;

  /* width: calc(100% - 34px); */
  height: calc(100% - 60px);
}

.p-sidebar-right .p-sidebar {
  width: 100% !important;
  height: 100% !important;
}
.p-sidebar .p-sidebar-header {
  padding: 10px !important;
  justify-content: start !important;
}
.p-sidebar-close-icon {
  color: var(--primary) !important;
}
.p-sidebar .p-sidebar-content {
  padding: 0 !important;
}
.p-sidebar-header {
  display: none !important;
}
.textArrowStyle {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 0.65rem !important;
  line-height: 18px !important;
  /* identical to box height */

  display: flex !important;
  align-items: center !important;

  color: #f7f7f7 !important;
  margin: 0 0 0 2px;
}
.p-inputtext:focus {
  border: 1px solid var(--white) !important;
  border-radius: 10px !important;
}
.sendIcon {
  /* color: var(--primary); */
  padding: 10px;
}
.chatAvatar {
  height: 30px !important;
  width: 30px !important;
  background: #f7f7f7 !important;
}

.time-font {
  font-style: normal !important;
  font-weight: 50 !important;
  font-size: 0.6rem !important;
  color: rgba(0, 0, 0, 0.45);
  line-height: 12px !important;
}
.weight-left {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  display: flex;
  align-items: flex-end;

  color: #ffffff;
}
.weight-right {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  display: flex;
  align-items: flex-end;

  color: #ffffff;
}
.name-to-left {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  display: flex;
  align-items: flex-end;

  color: #ffffff;
}
.tagValue {
  padding: 5px 10px !important;
  text-align: center !important;
  background: rgb(217 217 217 / 20%) !important;
  border-radius: 7.5px !important;
  /* background: rgba(0, 146, 221, 0.27) !important; */

  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 0.7rem !important;
  color: #000000 !important;
  opacity: 0.7 !important;
}
.p-card-body {
  padding: 0 !important;
}

.p-card-content {
  padding: 0 !important;
}
/* .rce-container-mbox {
    display: block;
    flex-direction: column;
    min-width: 300px;
    overflow-x: hidden;
  } */
.rce-mbox.rce-mbox-right {
  border-top-left-radius: 5px;
  border-top-right-radius: 0;
  float: right;
  margin-left: 5px;
  margin-right: 20px;
}
.rce-mbox {
  border-radius: 0 5px 5px;
  flex-direction: column;
  margin: 3px 5px 3px 20px;
  min-width: 140px;
  padding: 6px 9px 8px;
  position: relative;
}
.rce-mbox,
.rce-smsg {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgba(251, 224, 191, 1);
  /* 1px 1px 1px 1px #0003; */
  float: left;
}
.rce-mbox-right-notch {
  filter: drop-shadow(2px 0 1px rgba(0, 0, 0, 0.1));
  right: -14px;
}
.rce-mbox-left-notch,
.rce-mbox-right-notch {
  position: absolute;
  width: 15px;
  height: 15px;
  top: 0;
}
.rce-mbox-left-notch {
  left: -14px;
}
.dateTime {
  max-width: 100%;
  min-width: 75px;
  width: fit-content;
  /* height: 27px; */
  padding: 2px 10px;
  background: rgb(217 217 217 / 80%) !important;
  /* // rgba(0, 146, 221, 0.23) !important; */
  opacity: 0.34;
  border-radius: 7.5px;
  margin: 30px 0 15px 0;

  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;

  /* color: #000000; */

  opacity: 0.6;
}

.single-chat-wrapper {
  width: 100%;
  /* padding: 0 0 2px 0; */
  height: auto;
  overflow: hidden;
  display: block;
}
.rightMsg {
  position: relative;
  background: rgb(0 0 0 / 5%);
  /* // white  !important; */
  text-align: left;
  /* border: 1px solid rgba(251, 224, 191, 1); */
  border-radius: 7.5px 7.5px 7.5px 0 !important;
  /* border: 1px solid white; */
  float: left;
  left: 10px;
  /* box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
      0px 1px 8px 0px rgba(0, 0, 0, 0.12); */
}
.rightMsg::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 10px 10px;
  border-color: transparent transparent rgb(0 0 0 / 5%) transparent;
  bottom: 0;
  left: -10px;
  /* box-shadow: 0 0 0 0 rgb(0 0 0 / 0%), 0 0 0 0 rgb(0 0 0 / 0%), 0 0 0 0 rgb(0 0 0 / 0%), 0 2px 1px -1px rgb(0 0 0 / 20%); */
}
/* 
  .rightMsg::before {
    content: "";
    position: absolute;
    visibility: visible;
    bottom: -1px;
    left: -10px;
    border-bottom: 7.5px solid white;
  }
  
  .rightMsg::after {
    content: "";
    position: absolute;
    visibility: visible;
    bottom: 0px;
    left: -10px;
    border-left: 10px solid transparent;
    border-bottom: 7.5px solid white;
    clear: both;
  } */
.rightMsg-only {
  position: relative;
  /* background: white !important; */
  text-align: left;
  background: rgb(0 0 0 / 5%);

  border-radius: 7.5px;
  /* 7.5px 7.5px 0; */
  /* border: 1px solid var(--primary); */
  float: left;
  left: 10px;
  /* box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
      0px 1px 8px 0px rgba(0, 0, 0, 0.12); */
}

.right {
  position: relative;
  background: var(--primary) !important;
  text-align: left;

  border-radius: 7.5px 7.5px 7.5px 0 !important;
  /* border: 1px solid var(--primary); */
  float: left;
  left: 10px;
  /* box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
      0px 1px 8px 0px rgba(0, 0, 0, 0.12); */
}
.right::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 10px 10px;
  border-color: transparent transparent var(--primary) transparent;
  bottom: 0;
  left: -10px;
  /* box-shadow: 0 0 0 0 rgb(0 0 0 / 0%), 0 0 0 0 rgb(0 0 0 / 0%), 0 0 0 0 rgb(0 0 0 / 0%),
      0 2px 1px -1px rgb(0 146 221 / 20%); */
}

/* .right::before {
    content: "";
    position: absolute;
    visibility: visible;
    bottom: -1px;
    left: -10px;
    border-bottom: 7.5px solid var(--primary);
  }
  
  .right::after {
    content: "";
    position: absolute;
    visibility: visible;
    bottom: 0px;
    left: -10px;
    border-left: 10px solid transparent;
    border-bottom: 7.5px solid var(--primary);
    clear: both;
  } */
.right-only {
  position: relative;
  background: var(--primary) !important;
  text-align: left;

  border-radius: 7.5px;
  /* 7.5px 7.5px 0; */
  /* border: 1px solid var(--primary); */
  float: left;
  left: 10px;
  /* box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
      0px 1px 8px 0px rgba(0, 0, 0, 0.12); */
}

.left-chat {
  position: relative;
  background: var(--primary) !important;
  text-align: right;

  /* border: 1px solid var(--primary); */
  float: right;
  right: 10px;
  /* box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
      0px 1px 8px 0px rgba(0, 0, 0, 0.12); */

  border-radius: 7.5px 7.5px 0 7.5px !important;
  /* box-shadow: 0 2px 1px -1px rgb(0 146 221 / 20%), 0 1px 1px 0 rgb(0 146 221 / 14%), 0 0 0 0 rgb(0 146 221 / 0%); */
}
.left-chat::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 0 10px;
  border-color: transparent transparent transparent var(--primary);
  bottom: 0;
  right: -10px;
  /* box-shadow: 0 0 0 0 rgb(0 0 0 / 0%), 0 0 0 0 rgb(0 0 0 / 0%), 0 0 0 0 rgb(0 0 0 / 0%),
      0 2px 1px -1px rgb(0 146 221 / 20%); */
}
/* .left-chat::before {
    content: "";
    position: absolute;
    visibility: visible;
    bottom: -1px;
    right: -10px;
    border-bottom: 7.5px solid var(--primary);
  }
  
  .left-chat::after {
    content: "";
    position: absolute;
    visibility: visible;
    bottom: 0px;
    right: -10px;
    border-right: 10px solid transparent;
    border-bottom: 7.5px solid var(--primary);
    clear: both;
  } */
.left-chat-only {
  position: relative;
  background: var(--primary) !important;
  text-align: right;
  /* border: 1px solid var(--primary); */
  float: right;
  right: 10px;
  /* box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
      0px 1px 8px 0px rgba(0, 0, 0, 0.12); */

  border-radius: 7.5px;
  /* //7.5px 0 7.5px; */
}
.left-chat-only-web {
  position: relative;
  background: var(--primary) !important;
  text-align: right;
  /* border: 1px solid var(--primary); */
  float: right;
  right: 10px;
  /* box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
      0px 1px 8px 0px rgba(0, 0, 0, 0.12); */

  border-radius: 7.5px;
}

.left-chat-msg {
  position: relative;
  background: rgba(73, 158, 227, 0.1) !important;
  text-align: right;

  /* border: 1px solid rgba(73, 158, 227, 0.1); */
  float: right;
  right: 10px;
  /* box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
      0px 1px 8px 0px rgba(0, 0, 0, 0.12); */

  border-radius: 7.5px 7.5px 0 7.5px !important;
  /* box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 0 0 0 rgb(0 0 0 / 0%); */
}
/* .left-chat-msg::after {
    border-width: 10px 0 0 10px;
    border-color: transparent transparent transparent rgba(73, 158, 227, 0.1);
    bottom: 0;
    right: -10px;
    box-shadow: 0 0 0 0 rgb(0 0 0 / 0%), 0 0 0 0 rgb(0 0 0 / 0%), 0 0 0 0 rgb(0 0 0 / 0%), 0 2px 1px -1px rgb(0 0 0 / 20%);
  } */
/* .left-chat-msg::before {
    content: "";
    position: absolute;
    visibility: visible;
    bottom: -1px;
    right: -10px;
    border-bottom: 7.5px solid rgba(73, 158, 227, 0.1);
  } */

.left-chat-msg::after {
  /* content: "";
    position: absolute;
    visibility: visible;
    bottom: 0px;
    right: -10px;
    border-right: 10px solid transparent;
    border-bottom: 7.5px solid rgba(73, 158, 227, 0.1);
    clear: both; */

  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 0 10px;
  border-color: transparent transparent transparent rgba(73, 158, 227, 0.1);
  bottom: 0;
  right: -10px;
  /* box-shadow: 0 0 0 0 rgb(0 0 0 / 0%), 0 0 0 0 rgb(0 0 0 / 0%), 0 0 0 0 rgb(0 0 0 / 0%), 0 2px 1px -1px rgb(0 0 0 / 20%); */
}
.left-chat-msg-only {
  position: relative;
  background: rgba(73, 158, 227, 0.1) !important;
  text-align: right;
  /* border: 1px solid rgba(73, 158, 227, 0.1); */
  float: right;
  right: 10px;
  /* box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
      0px 1px 8px 0px rgba(0, 0, 0, 0.12); */

  border-radius: 7.5px;
  /* 7.5px 0 7.5px; */
}
.left-chat-msg-only-web {
  position: relative;
  background: rgba(73, 158, 227, 0.1) !important;
  text-align: right;
  /* border: 1px solid rgba(73, 158, 227, 0.1); */
  float: right;
  right: 10px;
  /* box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
      0px 1px 8px 0px rgba(0, 0, 0, 0.12); */

  border-radius: 7.5px;
}
.left-chat-padding {
  padding: 10px;
}
.left-chat-msg-padding {
  padding: 6px 8px 8px 9px;
}
.right-chat-padding {
  padding: 10px;
}
.user-name {
  width: 100%;

  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  /* line-height: 15px; */
  display: flex;
  align-items: flex-end;
  /* color: #ffffff; */
  /* margin-bottom: 5px; */
}
.colorBlack {
  color: #000000;
}
.colorWhite {
  color: #ffffff;
}
.weight-to-left {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  /* line-height: 17px !important; */
  /* identical to box height */

  display: flex !important;
  align-items: flex-end !important;

  color: #ffffff !important;
}
.weight-to-right {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 0.9rem !important;
  /* line-height: 17px !important; */
  /* identical to box height */

  display: flex !important;
  align-items: flex-end !important;

  /* color: #000000 !important; */
}
.p-overlaypanel-content {
  width: 172px !important;
  height: 45px !important;
  padding: 17px 13px 16px 12px !important;
  border-radius: 4px;
  margin-left: 0 !important;

  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  line-height: 12px !important;
  display: flex !important;
  align-items: flex-end !important;

  /* color: #000000 !important; */
}
.name-to-right {
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  /* line-height: 15px; */
  /* identical to box height */

  display: flex;
  align-items: flex-end;

  /* color: #000000; */
}
.chat-box-width {
  width: auto;
  max-width: calc(100% - 85px);
  min-width: 58%;
}
.chat-box-width-web {
  width: auto;
  max-width: calc(100% - 85px);
  min-width: 40%;
}
.chat-box-msg {
  width: auto;
  max-width: calc(100% - 85px);
  /* min-width: 40%; */
}
.minusIcon {
  border-top: 2px solid #ffffff;
  width: 9px;
}
.minusIconLeft {
  border-top: 2px solid #ffffff;
  width: 9px;
}
.wordBreak {
  white-space: normal;
  word-break: break-word;
}
.colorBlack {
  color: #000000 !important;
}
.margin-all {
  margin-bottom: 15px;
}

.margin-same {
  margin-bottom: 7px;
  /* margin-top: 2.5px; */
}
